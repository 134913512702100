import * as React from "react";
import Layout from "../components/Layout";
import Head from "../components/Head";
import AreasSelection from "../components/AreasSelection";

import { Container } from "@mui/material";

const AreasPage = () => (
  <Layout>
    <Head title="Focus areas" />
    {/* Content */}
    <div className="bg-gray-100">
      <Container maxWidth="xl" sx={{ pt: 15, pb: 25 }}>
        <h2 className="_h1 text-center">Business areas we are focusing on</h2>
        <AreasSelection />
      </Container>
    </div>
  </Layout>
);

export default AreasPage;
